import { RemixBrowser } from '@remix-run/react';
import { startTransition } from 'react';
import { setUserAgent } from 'react-device-detect';
import { hydrateRoot } from 'react-dom/client';

import { UserAgentProvider } from './utils/useragent-provider.ts';

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
	import('#app/utils/monitoring.client.tsx').then(({ init }) => init());
}

const callback = () =>
	startTransition(() => {
		hydrateRoot(
			document,
			<UserAgentProvider value={window.navigator.userAgent || ''}>
				<RemixBrowser />
			</UserAgentProvider>,
		);
	});

setUserAgent(window.navigator.userAgent);
callback();
